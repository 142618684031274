import fetch from "@/api/fetch";

const u = "/client/order/";

export function addProxy(onlyCalc,addressId,goodsId, insure, num, payType,remark,boxSize,sukId,requireInvoice,sendAddress) {

    return fetch({
        url: `${u}addProxy`,
        method: "post",
        data: {
			addressId,
            goodsId: goodsId,
            insure: insure,
            num: num,
			onlyCalc,
			payType,
			remark,
			boxSize,
			sukId,
			requireInvoice,
			sendAddress
   
        }
    })
}


export function addStandard(onlyCalc,addressId,goodsId, insure, num, payType,remark,boxSize,sukId,requireInvoice) {

    return fetch({
        url: `${u}addStandard`,
        method: "post",
        data: {
			addressId,
            goodsId: goodsId,
            insure: insure,
            num: num,
			onlyCalc,
			payType,
			remark,
			boxSize,
			sukId,
			requireInvoice
   
        }
    })
}





/**
 * 订单列表
 */
export function list(params) {
    return fetch({
        url: `${u}list`,
        method: "get",
        params
    })
}

/**
 * 测试支付
 */
export function testPay(orderId) {
    return fetch({
        url: `${u}pay/test`,
        method: "post",
        params: {orderId}
    })
}

/**
 * 微信支付
 */
export function wxPay(orderId) {
    return fetch({
        url: `${u}pay/wx`,
        method: "post",
        params: {orderId}
    })
}

/**
 * PayPal支付
 */
export function payPalPay(orderId) {
    return fetch({
        url: `${u}pay/payPal`,
        method: "post",
        params: {
            orderId,
            returnUrl: location.protocol + "//" + location.host + "/userhome/ordercenter/PayPalSuccess",//支付成功时跳转的地址，有三个参数，要
            cancelUrl: location.href
        }
    })
}

/**
 * PayPal支付
 */
export function payPalExecute(params) {
    return fetch({
        url: `/client/order/applyInvoice`,
        method: "post",
        params
    })
}


/**
 * alipay 支付宝支付
 */
export function alipayPalPay(orderId,returnUrl) {
    return fetch({
        url: `${u}pay/alipay`,
        method: "post",
        params: {
            orderId,
            returnUrl: location.protocol + "//" + location.host + "/orderadmin",//支付成功时跳转的地址，有三个参数，要
        }
    })
}
  
  // 他人代付
  
  export function elseorder(orderId,userId) {
      return fetch({
          url: `${u}assignReplacePayUser`,
          method: "post",
          params: {orderId,userId}
      })
  }
    
  
  // 订单详情
  
  export function orderDeatail(orderId) {
      return fetch({
          url: `${u}get`,
          method: "get",
		  
          params: {orderId}
      })
  }
    
	
	// orderInvoice
	
	export function orderInvoice(item) {
	    return fetch({
	        url: `/client/order/applyInvoice`,
	        method: "post",
	        params: {
	        	orderId:item.id
	        }
	    })
	}
	  
//银行支付
export function ccbPay(orderId) {
    return fetch({
        url: `${u}/pay/ccbPay`,
        method: "post",
        params: {
            orderId,
        }
    })
}
  

